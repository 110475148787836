import React from 'react';
import './App.css';

function App() {
  return (
    <div>
      <h1>Cameron Cipriano</h1>
      <p>Personal website go brrr</p>
    </div>
  );
}

export default App;
